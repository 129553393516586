<template>
  <!-- begin:: Aside -->
  <div class="brand flex-column-auto" id="dp_brand" ref="dp_brand">
    <div class="brand-logo">
      <router-link to="/">
        <h2 class="mt-2">Selling Share</h2>
      </router-link>
    </div>
  </div>
  <!-- end:: Aside -->
</template>
<style lang="scss" scoped>
.brand-logo img{
  width: 200px !important;
  margin-top: 1rem;
}
</style>

<script>
export default {
  name: 'DPBrand',
  components: {
    Logo: () => import('@/components/global/Logo'),
  },
};
</script>
